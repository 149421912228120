.app {
  display: flex;
  background-color: black;
}

.navbar {
  width: 200px;
  background: #f8f9fa;
  padding: 20px;
}

.navbar ul {
  list-style: none;
  padding: 0;
}

.navbar li {
  margin: 10px 0;
}

.navbar a {
  text-decoration: none;
  color: #333;
}

.content {
  flex: 1;
  padding: 20px;
}
